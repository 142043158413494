<template lang="">
    <div class="container">
        <div class="mb-3">
            <label class="form-label">إسم التصنيف</label>
            <input class="form-control" type="text" v-model="name" />
        </div>
        <div class="mb-3">
            <label class="form-label">صورة التصنيف</label>
            <input type="file" accept="image/*" @change="uploadImage($event)" id="file-input">
        </div>
        <div class="mb-3">
            <label class="form-label">صورة ثانية</label>
            <input type="file" accept="image/*" @change="uploadSecondImage($event)" id="file-input">
        </div>
        <div class="mb-3">
            <div class="form-group">
    <label for="exampleFormControlSelect1">جنس التصنيف</label>
    <select class="form-control" id="exampleFormControlSelect1" v-model="gender_id">
      <option  v-for="item in gender" :key="item.id" :value="item.id">{{item.name}}</option>
    </select>
  </div>

  
        </div>
        <div class="mb-3">
            <button class="btn btn-dark" type="button" @click="saveCategory">حفظ التصنيف</button>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name:'new-category',
    data(){
        return {
            gender:[],
            name:'',
            image:'',
            second_image:'',
            gender_id:'',
        }
    },
    created(){
      this.getCategory();
  },

    methods: {
       async saveCategory(){
        try {
            let formData = new FormData();
            formData.append('name', this.name);
            formData.append('gender_id', this.gender_id);
            formData.append('second_image', this.second_image);
            formData.append('image', this.image);

            await axios.post('https://boutiquette-store.ly/add-category', formData);

            this.name = ''
            this.image = ''
            this.gender_id = ''
            this.second_image = ''
            this.$router.push('/home');
            
        } catch (error) {
            null
        }
       },

       async getCategory(){
          try {
              const response = await axios.get('https://boutiquette-store.ly/genders')
              this.gender = response.data.data
       
          } catch (error) {
            null
          }
      },

      uploadImage(event) {
        this.image = event.target.files[0];
    },

    uploadSecondImage(event){
        this.second_image = event.target.files[0];
    }

      
    }

}
</script>
<style lang="">
    
</style>